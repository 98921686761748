div.svg-shape {
    svg {
        fill: white;
    }
}

footer {
    ul {
        list-style: none;
        padding-left: 0;
        li{
          .icon .iconinside{
            font-size: 50px;
            margin-right: 16px;
          }
        }
    }

    ul li a {
        text-decoration: none;
    }
    ul li .nav-link{
        text-decoration: none;
    }

    svg {
        font-size: 19px !important;
        margin-right: 5px !important;
        margin-top: -5px !important;
    }

    .widget-title,
    img {
        // margin-bottom: 30px;
    }
    .get_app{
        font-size: 24px;
        font-weight: 700;
    }

    .buttonapplynow{
        color:#006C67 ;
        text-decoration: none;
        text-align: center;
        background-color: white;
        border-radius: 10px;
        font-weight: 700;
        border: 0;
        padding: 14px 40.5px 14px 40.5px;
        // padding: 14px 40.5px 14px 40.5px;
        border: 2px solid #079f9f;
        font-size: 22px;
    }
    .buttonapplynow:hover{
        background-color: #006C67 !important;
        color: white !important;
        border-color: white !important;
        // box-shadow: 1px 1px 20px .1px white !important;
    }
    .buttonapply2{
        color: #006C67;
         background-color: #006C67;
         border-radius: 10px;
         font-weight: 700;
         border: 0;
         padding: 10px 20px;
         border: 3px solid white;
         font-size: 22px;
         display: flex;
        img{
            margin-right: 40px;
        }
    }
    .buttonapply2:hover{
        .footerbuttontext{
            text-decoration: underline !important;
        }
    }
    h6{
        font-size: 11px;
        font-weight: 400;
        color: #F9F8F6;
    }

    .f-b{
        font-size: 16px;
        font-weight: 400;
    }

    .widget-title {
        display: inline-block;
        position: relative;
        z-index: 1;
    }

    .f-item.about {
        margin-right: 30px;
    }

    .address {
        h5 {
            margin-bottom: 10px;
            // text-transform: capitalize;
            font-size: 17px;
        }

        li {
            display: flex;
        }

        li i {
            font-size: 30px;
            min-width: 50px;
            display: inline-block;
        }

        border-top: 1px solid rgba(255, 255, 255, 0.15);
        padding-top: 15px;
    }

    .link {
        li {
            margin-bottom: 15px;
        }

        li:last-child {
            margin-bottom: 0;
        }

        li a {
            color: #96aac1;
        }
        li .nav-link{
            color: #96aac1;
        }

        a:hover {
            color: #ffffff !important;
        }
        .nav-link:hover {
            text-decoration: underline;
            color: #ffffff !important;
        }
    }

    .footer-bottom {
        border-top: 1px solid rgba(255, 255, 255, 0.15);
        padding: 25px 0;

        li {
            display: inline-block;
        }

        p {
            margin: 0;
        }

        .text-right li {
            margin-left: 19px;
        }
    }
}
.circle-shape2 {
    position: relative;
    z-index: 1;
    cursor: pointer !important;
    &::after {
        position: absolute;
        
        top: -2.5rem;
        content: "";
        height: 80px;
        width: 80px;
        // background: rgba(249, 243, 243, 0.05);
        background: #FF6337;
        border-radius: 50%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        content: url('../assets/arrowup.png');
        // background-size: cover;
        padding-left: 24px;
        padding-top: 15px;
        background-size: 10px 10px;
        
    }
}

.footerbuttontext{
    margin-bottom: 0px;
    margin-top: 8px;
}