.privacypolicy {
    margin-top: 160px;
    // margin-top: 120px;

    .container {
        .row {
            p {
                font-size: 16px !important;
            }

            h4 {
                font-size: 18px !important;
                font-weight: 400 !important;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            h2 {
                font-size: 30px !important;
                font-weight: 400 !important;
            }

            h3 {
                margin-top: 15px;
                font-weight: 400 !important;
                font-size: 24px !important;
            }

            h5 {
                font-size: 14px !important;
                font-weight: 400 !important;
                margin-top: 20px !important;
            }

            ol {
                padding-left: 40px;
            }

            table {
                background-color: transparent !important;
                margin-left: 13px !important;

                th {
                    border: 1px solid #dddddd;
                    font-size: 16px;
                    font-weight: 500;
                }

                td {
                    border: 1px solid #dddddd;
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        }
    }

    &:before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('../assets/credFin-logo.png') 50% no-repeat fixed;
        opacity: 0.3;
        transform: rotate(-25deg);
        pointer-events: none;
        z-index: -1;
    }
}

.TermsCondition {
    margin-top: 160px;

    // margin-top: 120px;
    .container {
        .row {
            p {
                font-size: 16px !important;
            }

            h4 {
                font-size: 18px !important;
                font-weight: 400 !important;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            h2 {
                font-size: 30px !important;
                font-weight: 400 !important;
            }

            h3 {
                margin-top: 15px;
                font-weight: 400 !important;
                font-size: 24px !important;
            }

            h5 {
                font-size: 14px !important;
                font-weight: 400 !important;
                margin-top: 20px !important;
            }

        }
    }

    &:before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('../assets/credFin-logo.png') 50% no-repeat fixed;
        opacity: 0.3;
        transform: rotate(-25deg);
        pointer-events: none;
        z-index: -1;
    }

}

.GreivenceRedressal {
    margin-top: 160px;

    // margin-top: 120px;
    .container {
        .row {
            ol {
                padding-left: 40px;
            }

            p {
                font-size: 16px !important;
            }

            h4 {
                font-size: 18px !important;
                font-weight: 400 !important;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            h2 {
                font-size: 30px !important;
                font-weight: 400 !important;
            }

            h3 {
                margin-top: 15px;
                font-weight: 400 !important;
                font-size: 24px !important;
            }

            h5 {
                font-size: 14px !important;
                font-weight: 400 !important;
                margin-top: 20px !important;
            }

        }
    }

    &:before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('../assets/credFin-logo.png') 50% no-repeat fixed;
        opacity: 0.3;
        transform: rotate(-25deg);
        pointer-events: none;
        z-index: -1;
    }
}

.support {
    margin-top: 160px;
    margin-bottom: 100px;

    .modal {
        display: block !important;
        background: rgba(0, 0, 0, 0.4);

        .Activebtn2:hover {
            border: 0 !important;
            background-color: rgba(0, 108, 103, 1) !important;
            color: white !important;
        }

        .Activebtn:hover {
            border: 1px solid rgba(0, 108, 103, 1) !important;
            background-color: rgba(0, 108, 103, .2) !important;
            color: rgba(0, 108, 103, 1) !important;
        }
    }

    .container {

        h5{
            font-size: 22px;
            font-weight: 600;
        }
        .Activebtn:hover {
            border: 1px solid rgba(0, 108, 103, 1) !important;
            background-color: rgba(0, 108, 103, .2) !important;
            color: rgba(0, 108, 103, 1) !important;
        }

        .Activebtn2:hover {
            border: 0 !important;
            background-color: rgba(0, 108, 103, 1) !important;
            color: white !important;
        }
       
        div.otpInputGroup {
            display: flex;
            justify-content: space-between;

            input.otpDigit {
                padding: 10px;
                text-align: center;

                appearance: textfield;

                width: 75px;
                height: 48px;

                // background: #ECEBFF;
                border: none;
                border-bottom: 2px solid black;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;


            }
        }
        @media(max-width:576px){
            div.otpInputGroup {
                .otpDigit {
                 width: 45px !important;
                }
            }
        }
        // @media(max-width:768px){
        //     .heads1{
        //      img{
        //          width: 4%;
        //      }
        //     }
        //  }
        // @media(max-width:576px){
        //     .heads1{
        //      img{
        //          width: 8%;
        //      }
        //     }
        //  }  

        .heads1 {

            // background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)),
            //     linear-gradient(0deg, rgba(255, 163, 35, .4), rgba(255, 163, 35, .4));
            border-radius: 6px;
            border: 1px solid rgba(255, 163, 35, .4);

            h6 {
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
                margin-top: 3px;
                margin-bottom: 0px;
            }

        }
        .formbox{
            border: 1px solid rgba(1,74,148,.2);
            border-radius: 12px;
            hr{
                border: .1px solid rgba(1,74,148,.2);
            }
            
        }
    }
}


.CancellationPolicy {
    margin-top: 160px;
    margin-bottom: 80px;
    // margin-top: 120px;
   
    // background: url(http://localhost:3001/static/media/credFin-logo.9ee9196….png) no-repeat 50% fixed;
    // background-size: contain;
    
    .container {
        .row {
            p {
                font-size: 16px !important;
            }
            h2 {
                font-size: 30px !important;
                font-weight: 400 !important;
            }
            ul {
                padding-left: 40px;
            }
        }
    }

    &:before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('../assets/credFin-logo.png') 50% no-repeat fixed;
        opacity: 0.3;
        transform: rotate(-25deg);
        pointer-events: none;
        z-index: -1;
    }
}

.ourPartner{
    margin-top: 160px;
    margin-bottom: 100px;
    h1{
        text-align: center;
        font-size: 40px;
    }
    .box{
        box-shadow: 8px 8px 20px 3px #a7a3a3;
        // box-shadow: 8px 8px 20px 3px #a7a3a3, inset 6px 6px 12px -2px #006c67;
        padding: 20px;
        margin-top: 30px;
        border-radius: 10px;
        // border: 2px solid black;
        width: 600px;
        background-color: #f9f9f9;
        text-align: center;
        h4{
            font-weight: 500;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        h6{
            padding-left: 70px;
            padding-right: 70px;
            font-size: 20px;
            margin-bottom: 20px;
        }
        a{
            font-size: 18px;
            text-decoration: none;
            &:hover{
                text-decoration: underline !important;
            }
        }
    }
}

@media (max-width: 576px) {
    .ourPartner{
        .box{
            h6{
                padding-left: 0px !important;
                padding-right: 0px !important;
            }
            
        }
    }
}