body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-webkit-scrollbar {
	width: 8px;
	height: 8px
}

::-webkit-scrollbar-track {
	box-shadow: inset 5px 5px 5px #f2fbfc;
}

::-webkit-scrollbar-thumb {
	background: #ff6337;
	border-radius: 8px
}
