nav {
   transition: all 0.5s ease;
   .btn{
      padding-left: 40px;
      padding-right: 40px;
      padding-top: 12px !important;
      padding-bottom: 12px !important;
      border-radius: 10.7px;
      color: #006C67;
      font-weight: 500;
   }
   li.nav-item a {
      color: black !important;
   }

   &.scrolled,
   &.opened {
      background: white;
   }

   &.scrolled {
      &.opened {
         // background-color: #003844;
         background-color: rgb(0, 108, 103);
      }

      &.opened li.nav-item a {
         color: white !important;
      }

      .navbar-brand img,
      &.navbar.opened .navbar-brand img {
         content: url('../assets/credFin-logo-white.png');
      }

      &.opened .btn:hover {
         // box-shadow: 3px 5px 10px .1px black;
         // box-shadow: 3px 5px 10px 1px rgb(0, 108, 103);
         border: 2px solid white;
         //   background-color: #003844 !important;
         background-color: #1baaa0 !important;
         color:white !important;
         // background-color: rgb(0, 108, 103) !important;
      }

      &.opened .btn {
         background-color: white !important;
         color: #003844 !important;
      }

   }

   &.opened li.nav-item a {
      color: #003844 !important;
   }

   &.opened .btn:hover {
      transition: all .3s ease-in;
      color: #1baaa0 !important;
      background-color: white !important;
      // box-shadow: 3px 5px 10px 1px rgb(0, 108, 103);
      border: 2px solid #1baaa0;
   }

   &.scrolled li.nav-item a {
      color: #003844 !important;
      
   }

   .btn:hover {
      transition: all .3s ease-in;
      background-color: #1baaa0 !important;
      color: white !important;
      // box-shadow: 3px 5px 10px 1px white;
      border: 2px solid white;
   }

   &.scrolled .btn,&.opened .btn {
      //   color: white !important;
      //   background-color: #003844 !important;
      //  box-shadow: 5px 5px 10px 5px #003844;
      // border:2px solid #003844;
      background-color: #1baaa0 !important;
      color: white !important;

   }

   &.scrolled .btn:hover {
      transition: all .3s ease-in;
      background-color: white !important;
      color:#1baaa0 !important;
      font-weight: 400 !important;
      // box-shadow: rgb(0, 108, 103) 5px 5px 15px 1px;
      border: 2px solid #1baaa0;
   }
   
   .navbar-brand img {
      content: url('../assets/credFin-logo.png');
      // content: url('../assets/credFin-logo-white.png');
   }

   &.navbar.scrolled .navbar-brand img,
   &.navbar.opened .navbar-brand img {
      content: url('../assets/credFin-logo.png');
   }
}

.navbar-toggler:focus {
   box-shadow: none;
}
@media (min-width:992px){
   nav{
      li.nav-item a {
         font-size: 14px !important;
         margin-right: 10px !important;
      }
   }
}
@media (min-width:1024px){
   nav{
      li.nav-item a {
         margin-right: 10px !important;
         // margin-right: 20px !important;
      }
   }
}
@media (min-width:1200px){
   nav{
      li.nav-item a {
         margin-right: 35px !important;
      }
   }
}
@media (min-width:1400px){
   nav{
      li.nav-item a {
         font-size: 16px !important;
         margin-right: 50px !important;
      }
   }
}