$gradientcolor: linear-gradient(90deg, #5CA19D 0%, #006C67 99.7%);
$colorgreen : #006C67;
$marginTop: 120px;
$backcolorwhite: white;
$backcolorblack: black;
$colorgreen1 : #1baaa0;
$paddingheadingleft: 32px;
$borderHeading: 6px solid #FF6337;


.banner-area {
    position: relative;
    overflow: hidden;


    &.circle-shape {
        position: relative;
        z-index: 1;

        &::before {
            position: absolute;
            right: -430px;
            top: -80px;
            content: "";
            height: 861px;
            width: 861px;
            // background: rgba(249, 243, 243, 0.05);
            background: linear-gradient(272deg, rgba(0, 108, 103, 0.72) 54.69%, rgba(0, 108, 103, 0.00) 102.24%);
            border-radius: 50%;
            z-index: -1;
        }
    }

    &.auto-height,
    &.auto-height div {
        height: auto;
    }

    &.auto-height .content-box {
        padding-bottom: 180px;
        padding-top: 200px;
    }

    .thumb-innner {
        position: relative;
        z-index: 1;
    }

}
@media (max-width: 576px) {
    .banner-area {
        &.circle-shape {
            &::before {
                content: none !important;
            }
        }
        &.auto-height .content-box {
            padding-bottom: 90px !important;
        }
    }
}

body,
.banner-area,
.banner-area div {
    height: 100%;
}

// .text-light {
//     color: black !important;
// }
.banner-area {
    .container {
        .info {
            align-self: end;

            h2 {
                font-size: 58px;
                font-weight: 700;
                color: $backcolorblack;
            }

            h6 {
                color: #5c5c5c;
                font-size: 24px;
                font-weight: 400;
                margin-top: 30px;
            }

            .btn {
                padding: 20px 50px;
                border-radius: 10px;
                background-color: $colorgreen;
                color: $backcolorwhite;
                font-size: 24px;
                font-weight: 700;
                margin-top: 60px;
            }

            .btn:hover {
                box-shadow: 5px 5px 5px 1px $colorgreen;
                color: $colorgreen;
                background-color: $backcolorwhite;

            }
        }
    }
}

.shape-left-bottom {
    position: absolute;
    right: 0;
    top: 0;
    height: 100% !important;
    width: 100%;
    z-index: -1;

    img {
        height: 100%;
        max-width: 100%;
        min-width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }
}

img {
    border: none;
    outline: none;
    max-width: 100%;
}

.bg-gradient {
    background: $colorgreen !important;

}

.tabs {
    .container {
        .row {
            .itemsfirst {
                padding: 40px 30px;
                background-color: $backcolorwhite;
                box-shadow: 0px 5px 3px 0px rgba(214, 215, 216, 0.57);

                .icon {
                    width: 30px;
                    height: 30px;
                    color: $colorgreen ;
                    padding: 0px;
                }

                h4 {

                    margin-top: 10px;
                    font-size: 22px;
                    font-weight: 600;
                    color: #212529;
                }

                h5 {
                    color: rgba(0, 0, 0, 0.70);
                    font-size: 20px;
                    font-weight: 400;
                }
            }

            .itemsfirst:hover {
                box-shadow: 5px 5px 5px 5px rgba(214, 215, 216, 0.8) !important;
            }
        }
    }
}


.about-features-area {
    margin-top: $marginTop;

    .columnpadding {
        padding-top: 45px;
        padding-bottom: 25px;
    }

    .headingabout {
        padding-left: $paddingheadingleft;
        padding-right: 90px;
        padding-top: 0px;
        padding-bottom: 0px;
        border-left: $borderHeading;

        h5 {
            color: $colorgreen1;
            text-transform: uppercase;
        }
    }

    .textabout {
        .para {
            color: rgba(0, 0, 0, 0.70);
            font-size: 16px;
            font-weight: 400;
            line-height: 23px !important;
        }
    }

    .process-box {
        .item {
            padding: 40px 30px;
            background: #ffffff;
            box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
            margin-top: 30px;

            h4 {
                text-transform: capitalize;
                font-size: 22px;
                margin-top: 2rem;
            }
        }

        .item:hover {
            box-shadow: 5px 5px 5px 5px rgba(214, 215, 216, .8);
        }
    }
}

@media (max-width:768px) {
    .whatweoffer {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
}

// .heading-divider {
//     display: inline-block;
//     position: relative;
//     height: 5px;
//     border-radius: 30px;
//     -webkit-border-radius: 30px;
//     -moz-border-radius: 30px;
//     background-color: #89d4d0;
//     width: 90px;
//     overflow: hidden;

//     &::after {
//         content: '';
//         position: absolute;
//         left: 0;
//         top: -1.1px;
//         height: 7px;
//         width: 8px;
//         background-color: #003844;
//         -webkit-animation-duration: 3s;
//         animation-duration: 3s;
//         -webkit-animation-timing-function: linear;
//         animation-timing-function: linear;
//         -webkit-animation-iteration-count: infinite;
//         animation-iteration-count: infinite;
//         -webkit-animation-name: infinite-move;
//         animation-name: infinite-move;
//     }
// }

// @keyframes infinite-move {
//     from {
//         transform: translateX(0);
//     }

//     to {
//         transform: translateX(88px);
//     }
// }

.whatweoffer {
    margin-top: $marginTop;
    background: linear-gradient(90deg, #5CA19D 0%, #006C67 99.7%);
    padding: 49px 112px;

    .container {
        .row {
            .offerheading {
                float: right;
                text-align: right;
                padding-right: $paddingheadingleft;
                border-right: $borderHeading;

                h5 {
                    color: rgba(255, 255, 255, 0.6);
                    font-size: 22px;
                    font-weight: 700;
                }

                h4 {
                    color: $backcolorwhite;
                    font-size: 35px;
                    font-weight: 700;
                    margin-bottom: 0px;
                }
            }
        }

        .row {
            .wwo-1 {
                padding: 30px;

                .h6 {
                    color: $backcolorwhite;
                    font-size: 20px;
                    font-weight: 700;
                    margin-top: 18px;
                }

                h6 {
                    color: $backcolorwhite;
                    font-size: 18px;
                    line-height: 23px !important;
                    font-weight: 400;
                }
            }
        }
    }
}

.services-area {
    .container {
        .row {
            .col {
                .site-heading {
                    padding-left: $paddingheadingleft;
                    border-left: $borderHeading;

                    .heads {
                        color: $colorgreen1;
                    }

                    h3 {
                        color: $backcolorblack;
                    }
                }
            }
        }

    }

    .container-lg {
        margin-top: 54px;
    }
}

div.item.card {
    padding: 60px 37px;
    background: #ffffff;
    box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.57);
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-right: 30px;
    height: 496px;

    div.icon {
        margin: auto;
        padding: 2rem;
        border-radius: 8px;
        box-shadow: 0px 10px 30px 0px rgba(44, 130, 237, 0.4);
    }

    div.info {
        padding-top: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .card-title {
            text-align: center;
            margin: 1rem 0;

            a {
                color: #232323;
                text-decoration: none;
                margin: 1rem 0;
            }
        }


        .card-text {
            text-align: center;
            color: #666666;
            line-height: 200%;
        }

        a.card-link {
            // color: #634ded;
            margin: auto;
            text-decoration: none;
            padding: 12px 36px;
            background: #624ded15;
            border-radius: 100px;
            transition: 0.3s all ease;
            // background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), #006C67;
            color: $colorgreen ;

            &:hover {
                color: $backcolorwhite;
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), #006C67;
            }
        }
    }
}

div.item {
    img.card-curve-lines {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 50%;
        opacity: 0.4;
    }
}

.Loan-steps-third {
    margin-top: $marginTop;

    .container {
        .row {
            .heading {
                border-left: $borderHeading;
                padding-left: $paddingheadingleft;

                .h3 {
                    color: $colorgreen1;
                }

                h3 {
                    color: $backcolorblack;
                }
            }
        }

        .row {
            .imagess {
                .images img {
                    transition: 2s ease-in;
                }
            }
        }

        .row {
            .columntext {

                .text1,
                .text2,
                .text3 {
                    padding-left: 40px;
                    padding-bottom: 30px;
                    border-left: 6px solid rgba(0, 108, 103, .2);

                    .box {
                        border-radius: 20px;
                        padding-top: 46px;
                        padding-right: 78px;
                        padding-bottom: 37px;
                        padding-left: 30px;
                        box-shadow: 5px 5px 30px 0px rgba(136, 136, 136, 0.25);

                        .h6 {
                            color: #212529;
                            font-size: 20px;
                            line-height: 23px !important;
                            font-weight: 500;
                        }

                        h6 {
                            line-height: 23px !important;
                            color: #000;
                            font-size: 16px;
                            font-weight: 400;
                        }
                    }
                }

                .activetext {
                    border-left: 6px solid $colorgreen !important;
                    transition: .8s ease-in;

                    .activebox {
                        transition: .8s ease-in;
                        background: var(--tertiary-teal, linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), #006C67);

                    }
                }
            }
        }
    }
}

.Loan-steps-fourth {
    .container {
        .row {
            .heading {
                border-left: $borderHeading;
                padding-left: $paddingheadingleft;

                .h3 {
                    color: $colorgreen1;
                }

                h3 {
                    color: $backcolorblack;
                }
            }
        }

        .row {

            .rowfirst,
            .rowsecond,
            .rowthird {
                padding: 15px;
                text-align: center;

                h5 {
                    font-size: 1.5rem;
                }
            }

            .rowfirstimg,
            .rowsecondimg,
            .rowthirdimg {
                text-align: center;
            }
        }
    }
}


@media (max-width:920px) {
    .Loan-steps-third {
        .container {
            .row {

                .img1,
                .img3 {
                    padding-right: 25px !important;

                    .card,
                    .active-card {
                        width: 300px !important;
                        height: 300px !important;

                        img {
                            width: 80% !important;
                        }
                    }
                }

                .img2 {
                    padding-left: 25px !important;

                    .card,
                    .active-card {
                        width: 300px !important;
                        height: 300px !important;

                        img {
                            width: 40% !important;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .Loan-steps-third {
        display: none !important;
    }

    .Loan-steps-fourth {
        display: block !important;

        .container {
            .row {
                .rowfourth {
                    .btn:hover {
                        transition: all .1s ease-in;
                        background-color: $backcolorwhite !important;
                        color: $colorgreen !important;
                        box-shadow: 5px 5px 10px 5px $colorgreen !important;
                    }
                }
            }
        }
    }

}

@media (max-width: 1200px) {
    .Loan-steps {
        display: none !important;
    }

    .Loan-steps-secondone {
        display: block !important;

        .container {
            .row {
                .rowseventh {
                    .btn:hover {
                        transition: all .1s ease-in;
                        background-color: $backcolorwhite !important;
                        color: $colorgreen !important;
                        box-shadow: 5px 5px 10px 5px $colorgreen !important;
                    }
                }
            }
        }
    }

}

@media (max-width :767px) and (min-width : 576px) {
    .Loan-steps-secondone {
        .container {
            .row {

                .rowfirst,
                .rowsecond,
                .rowthird,
                .rowfourth,
                .rowfifth,
                .rowsixth {
                    img {
                        width: 50% !important;
                    }
                }
            }
        }
    }
}

@media (max-width :575px) {
    .Loan-steps-secondone {
        .container {
            .row {

                .rowfirst,
                .rowsecond,
                .rowthird,
                .rowfourth,
                .rowfifth,
                .rowsixth {
                    img {
                        width: 70% !important;
                    }
                }
            }
        }
    }
}


// Emi calculator 
.calculator {
    .container {
        .row {
            // margin-bottom: 10px;

            .col .heading {
                padding-left: $paddingheadingleft;
                border-left: $borderHeading;

                h3 {
                    color: $backcolorblack;
                    font-size: 35px;
                }
            }
        }

        #rowsfirst {
            box-shadow: 6px 5px 15px 9px #e1e1e1;
            border-radius: 12px;
            margin-top: 20px;
            padding-bottom: 20px;
        }

        .row {
            background-color: $backcolorwhite;

            .sliders {
                .LoanAmount {
                    .row {
                        .col {

                            .MuiSlider-root {

                                .MuiSlider-track,
                                .MuiSlider-rail {

                                    background: $gradientcolor !important;
                                }

                                .MuiSlider-thumb {
                                    width: 35px;
                                    height: 35px;
                                    color: $backcolorwhite !important;
                                    border: 8px solid $colorgreen !important;

                                }
                            }
                        }
                    }
                }

                .InterestRate {
                    .row {
                        .col {
                            .MuiSlider-root {

                                .MuiSlider-track,
                                .MuiSlider-rail {
                                    // color: #006C67 !important;

                                    background: $gradientcolor !important;
                                }

                                .MuiSlider-thumb {
                                    width: 35px;
                                    height: 35px;
                                    color: $backcolorwhite !important;
                                    border: 8px solid $colorgreen !important;

                                }
                            }
                        }
                    }
                }

                .SelectEmiOption {
                    .row {
                        .col {
                            .MuiSlider-root {

                                .MuiSlider-track,
                                .MuiSlider-rail {

                                    background: $gradientcolor !important;
                                }

                                .MuiSlider-thumb {
                                    width: 35px;
                                    height: 35px;
                                    color: $backcolorwhite !important;
                                    border: 8px solid $colorgreen !important;

                                }
                            }
                        }
                    }
                }

                .input-group {
                    float: right;
                    max-width: 127px;
                    height: 50px;
                    border-radius: 8px;

                    .form-control {
                        color: $colorgreen ;
                        font-weight: 700;
                        font-size: 20px;
                        background-color: rgba(0, 108, 103, .3);
                    }
                }

                .valuelabels {
                    opacity: .5;
                    font-size: 15px;
                }
            }

            .emi {
                border-radius: .25rem;
                border: 1px solid rgba(0, 0, 0, .125);

                .row {
                    .emivalue {
                        text-align: center;
                        padding-top: 3rem;
                        border-bottom: 1px solid rgba(0, 0, 0, .125);
                        padding-bottom: 2rem;
                        background-color: rgba(0, 90, 70, .7);

                        h5 {
                            font-weight: 500;
                            font-size: 20px;
                            color: $backcolorwhite;
                        }

                        h3 {
                            color: $backcolorwhite;
                        }
                    }

                    .calculations {
                        padding-left: 15px;
                        padding-right: 15px;
                        padding-top: 16px;

                        hr {
                            border-style: dashed;
                            border-width: 1px;
                            color: rgba(0, 0, 0, .425);
                        }

                        .row {
                            .h5 {
                                font-weight: 500;
                            }

                            .h5values {
                                float: right;
                                color: #4285F4;
                                font-weight: 500;
                            }
                        }

                        .row {
                            h5 {
                                font-weight: 300;
                                font-size: 15px;
                            }

                            .values {
                                float: right;
                                font-weight: 600;
                            }
                        }


                    }
                }
            }
        }
    }
}

.buttonapply {
    background-color: $colorgreen ;
    text-decoration: none;
    text-align: center;
    color: $backcolorwhite;
    border-radius: 10px;
    font-weight: 400;
    border: 0;
    padding: 11px 26px;
}

.buttonapply:hover {
    background-color: $backcolorwhite !important;
    color: $colorgreen !important;
    // box-shadow: 5px 5px 10px 1px #003844 !important;
    border: 2px solid $colorgreen;
}




@media (max-width:1400px) and (min-width:991px) {
    .calculator {
        .container {
            .row {
                .emi {
                    .row {
                        .emivalue {
                            h5 {
                                font-size: 15px !important;
                            }

                            h3 {
                                font-size: 20px !important;
                            }
                        }

                        .calculations {
                            .row {
                                .col {
                                    h5 {
                                        font-size: 15px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:415px) {
    .calculator {
        .container {
            .row {
                .sliders {
                    .LoanAmount {
                        .row .col {
                            h5 {
                                font-size: .9rem !important;
                            }
                        }
                    }

                    .InterestRate {
                        .row .col {
                            h5 {
                                font-size: .9rem !important;
                            }
                        }
                    }

                    .SelectEmiOption {
                        .row .col {
                            h5 {
                                font-size: .9rem !important;
                            }
                        }
                    }
                }

                .emi {
                    .row {
                        .calculations {
                            .row {
                                .col {
                                    h5 {
                                        font-size: .89rem !important;
                                    }

                                    .h5 {
                                        font-size: 1.1rem !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//FAQ 
.accordion-1 {
    margin-top: 160px;

    .faqheaidng {
        padding-left: $paddingheadingleft;
        border-left: $borderHeading;
    }

    .buttonapply1 {
        color: $colorgreen ;
        text-align: center;
        background-color: $backcolorwhite;
        border-radius: 10px;
        font-weight: 400;
        border: 0;
        padding: 11px 26px;
        border: 2px solid #079f9f;
    }

    .buttonapply1:hover {
        color: $backcolorwhite !important;
        border-color: white;
        background-color: $colorgreen !important;
        // box-shadow: 5px 5px 10px 1px #003844 !important;
    }

    .img img {
        float: right;
    }
}

// Lending Partner 

.LendingPartner {
    .container {
        padding-left: 150px;
        padding-right: 150px;

        .block1 {
            border-radius: 12px;
            padding: 20px;
            background: linear-gradient(90deg, #003844 0%, #046E69 78.94%);
        }

        h4 {
            font-size: 35px;
            font-weight: 700;
            color: white;
        }

        p {
            line-height: 38.4px;
            font-size: 18px;
            font-weight: 400;
            color: white;

            // span {
            //     line-height: 38.4px;
            //     font-size: 18px;
            //     font-weight: 700;
            //     color: white;
            // }
        }

        .btnss {
            text-align: left;
        }

        h5 {
            color: white;
            font-size: 20px;
            font-weight: 600;
        }

        .button10 {
            background-color: white;
            border-radius: 12px;
            padding-top: 10px;
            padding-right: 18.55px;
            border: 0px;
            padding-bottom: 14px;
            padding-left: 28px;

            a {
                color: rgba(0, 108, 103, 1);
                text-decoration: none;
            }
        }

        .button10:hover {
            background-color: $colorgreen !important;
            border: 1px solid white !important;

            a {
                color: $backcolorwhite !important;
            }
        }

        .arhamlink {
            background-color: white;
            border-radius: 12px;
            padding-top: 10px;
            padding-right: 18.55px;
            border: 0px;
            padding-bottom: 14px;
            padding-left: 28px;


            color: rgba(0, 108, 103, 1);
            text-decoration: none;

        }

        .arhamlink:hover {
            background-color: $colorgreen !important;
            // box-shadow: 5px 5px 5px .1px white !important;
            border: 1px solid white !important;


            color: $backcolorwhite !important;

        }
    }
}

@media (max-width :768px) {

    .LendingPartner {
        .container {
            padding-left: 0px !important;
            padding-right: 0px !important;

            .block1 {
                padding-left: 10px !important;
                padding-right: 10px !important;
            }
        }
    }
}

@media (max-width :470px) {

    .LendingPartner {
        .container {
            .block1 {
                .row {
                    padding-left: 5px !important;
                    padding-right: 5px !important;

                    .col {
                        padding-left: 10px !important;
                        padding-right: 10px !important;
                    }
                }

                .rows1 {
                    .col {
                        display: block !important;

                        .imgs {
                            img {
                                width: 25% !important;
                            }
                        }
                    }
                }
            }
        }
    }
}


.accordion-1 {
    position: relative;
    overflow: hidden;


    &.circle-shape-faq {
        position: relative;
        z-index: 1;

        &::before {
            position: absolute;
            right: -430px;
            top: 0px;
            content: "";
            height: 746px;
            width: 838px;
            background: linear-gradient(272deg, rgba(0, 108, 103, 0.72) 54.69%, rgba(0, 108, 103, 0.00) 102.24%);
            border-radius: 50%;
            z-index: -1;
            // -webkit-animation-duration: 1.5s;
            // animation-duration: 1.5s;
            // -webkit-animation-timing-function: linear;
            // animation-timing-function: linear;
            // -webkit-animation-iteration-count: 1;
            // animation-iteration-count: 1;
            // -webkit-animation-name: infinite-move;
            // animation-name: infinite-move;
        }
    }

    .beforeimgfirst {
        position: relative;
        overflow: hidden;

        &.circle-shape-faq2 {
            position: relative;
            z-index: 1;

            &::before {
                position: absolute;
                // right: -430px;
                left: -200px;
                top: 770px;
                content: "";
                height: 400px;
                width: 400px;
                background: linear-gradient(272deg, rgba(0, 108, 103, 0.72) 54.69%, rgba(0, 108, 103, 0.00) 102.24%);
                border-radius: 50%;
                z-index: -1;
                // -webkit-animation-duration: 1.5s;
                // animation-duration: 1.5s;
                // -webkit-animation-timing-function: linear;
                // animation-timing-function: linear;
                // -webkit-animation-iteration-count: 1;
                // animation-iteration-count: 1;
                // -webkit-animation-name: infinite-move-left;
                // animation-name: infinite-move-left;
            }
        }

    }

    .container {
        .accordion {
            .accordion-button:not(.collapsed) {
                color: #000 !important;
            }
        }
    }

}


@keyframes infinite-move {
    from {
        // transform: rotate(0);
        transform: translateX(500px);
    }

    to {
        transform: translateX(-80px);
        // transform: rotate(360deg);
    }
}

@keyframes infinite-move-left {
    from {
        // transform: rotate(0);
        transform: translateX(-500px);
    }

    to {
        transform: translateX(0px);
        // transform: rotate(360deg);
    }
}

.AboutusPage {
    margin-top: 160px;
    margin-bottom: 80px;

    .container {
        .row {
            p {
                font-size: 16px !important;
            }

            h2 {
                font-size: 30px !important;
                font-weight: 400 !important;
            }

            ul {
                padding-left: 40px;
            }
        }
    }
}

.Contactus {
    margin-top: 160px;
    margin-bottom: 80px;

    .container {
        .row {
            h2 {
                font-size: 30px !important;
                font-weight: 400 !important;
            }
            .contactinfo{
                display: flex;
                flex-direction: column;
                // align-items: center;
                align-self: center;
            }
            .content{
                margin-top: 20px;
            }

            .heads {
                color: #006C67;
                // margin-bottom: 0px !important;
                font-size: 20px !important;
                display: flex;
                align-self: center;
                margin-left: 15px;
            }
            h5{
                margin-top: 10px;
                margin-left: 15px;
            }
        }
    }
}
.popuppagebtn{
    color: rgba(0, 108, 103, 1);
    font-weight: 700;
    font-size: 14px;
    border-radius: 10.7px;
    background-color: rgba(0, 108, 103, .2);
    box-shadow: 0px 0px 0px 8.55813980102539px rgba(255, 255, 255, 0.15);
    padding: 13px 45px;
    border: 1px solid rgba(0, 108, 103, 1);
}


.LendingPartnerNew{
    .headingss{
        h4{
            font-size: 35px ;
            font-weight: 700;
            text-align: center;
        }
    }
    .blocks{
        border-radius: 12px;
        padding: 20px 15px;
        margin: 30px 0;
        &.ArhamBox{
            background: linear-gradient(90deg, #003844 0%, #046E69 78.94%);
            text-align: center;
            .btnss{
                h5{
                    color: #fff;
                    font-size: 18px;
                }
                .arhamlink{
                    margin-top: 15px;
                    background-color: white;
                    border-radius: 12px;
                    padding: 10px 14px;
                    border: 0px;
                    color: rgb(0, 108, 103);
                    text-decoration: none;
                    &:hover{
                        background-color: rgb(0, 108, 103);
                        color: #fff;
                        border: 1px solid #fff;
                    }
                }
               
            }
        }
        &.firstFiscal{
            background-color: rgba(42, 108, 146, 0.7);
            text-align: center;
            .btnss{
                h5{
                    color: #fff;
                    font-size: 18px;
                }
                .arhamlink{
                    margin-top: 15px;
                    background-color: white;
                    border-radius: 12px;
                    padding: 10px 14px;
                    border: 0px;
                    color: rgb(0, 48, 73);
                    text-decoration: none;
                    &:hover{
                        background-color: rgb(0, 48, 73);
                        color: #fff;
                        border: 1px solid #fff;
                    }
                }
               
            }
        }
    }
}